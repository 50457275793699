* {
    margin: 0;
    font-family: Helvetica;
}

.footerBackground{
    background-color: rgb(129, 129, 129);
    width: 100%;
}

.facebookIcon{
    color: rgb(131, 158, 245);
}

.instagramIcon{
    color: rgb(230, 131, 177);
}

.twitterIcon{
    color: rgb(49, 216, 49);
}

.footerUI li{
    cursor: pointer;
}

.footerHover{
    color: white;
    text-decoration: none;
}

.footerHover:hover{
    color: rgb(175, 248, 185);
    text-decoration: underline;
}
