*{
    margin: 0;
    font-family: Helvetica;
}

.applyTitle{
    color: rgb(58, 136, 96);
}

.backBtn{
    margin-top: 2rem;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    background-color: rgb(235, 144, 69);
    box-shadow: 4px 4px 6px rgb(199, 199, 199);
    color:rgb(54, 54, 54)
}

.backBtn:hover{
    background-color: rgba(235, 144, 69,0.6);
    box-shadow: 2px 2px 3px rgb(131, 131, 131);
}

