.posterWrapper{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 5rem;
}

.positionTitleWrapper{
    background-color: rgb(241, 241, 241);
    box-shadow: 3px 3px 6px rgb(145, 144, 144);
}

.applyBtn{
    background-color: rgb(247, 148, 83);
    padding: 0.8rem 2rem;
    border: 0;
    font-weight: bold;
    color: rgb(54, 54, 54);
}

.applyBtn:hover{
    background-color: rgb(175, 248, 185);
}

